<template>
  <div id="app">
    <Login />
  </div>
</template>

<script>
import Login from '@/components/login.vue'

export default {
  name: 'App',
  components: {
    Login,
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
