<template>
  <div class="login-main">
    <div class="content">
      <div class="left"></div>
      <div class="right">
        <div id="formlogin">
          <div class="loginTile">欢迎登录</div>
          <el-form
            ref="ruleFormRef"
            :model="form"
            :validate-on-rule-change="false"
            :rules="rules"
            class="demo-ruleForm"
          >
            <el-form-item prop="username" class="input-content">
              <div class="username-box input-box">
                <span class="i-username"></span>
                <el-input
                  v-model.trim="form.username"
                  maxlength="20"
                  type="text"
                  placeholder="请输入账号"
                  name="username"
                  class="logininput"
                  @keyup.enter="onSubmit"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="password" class="input-content">
              <div class="password-box input-box">
                <span class="i-password"></span>
                <el-input
                  v-model.trim="form.password"
                  type="password"
                  maxlength="20"
                  placeholder="请输入密码"
                  name="password"
                  class="logininput"
                  @keyup.enter="onSubmit"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="validateCode" class="encrypedInput input-content">
              <div class="validateCode-box">
                <span class="i-validateCode"></span>
                <el-input
                  v-model.trim="form.validateCode"
                  type="text"
                  maxlength="4"
                  autocomplete="off"
                  placeholder="请输入验证码"
                  name="validateCode"
                  class="logininput"
                  @keyup.enter="onSubmit"
                ></el-input>
                <span class="encrypedCodeImg">
                  <img :src="picUrl" alt="验证码" @click="changeCode" />
                </span>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="login-btn" :loading="btnLoading" @click="onSubmit">登录</el-button>
            </el-form-item>
          </el-form>
          <div class="bottom-content">
            <el-checkbox v-model="rembered">自动登录</el-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="bottmLogo">
      © 2019-2024 cqanfutong.com, All Rights Reserved&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备19016727号-1</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010502003018"
        >渝公网安备50010502003018</a
      >
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/images/validcode/1.png'
import img2 from '@/assets/images/validcode/2.png'
import img3 from '@/assets/images/validcode/3.png'
import img4 from '@/assets/images/validcode/4.png'
import img5 from '@/assets/images/validcode/5.png'
import img6 from '@/assets/images/validcode/6.png'
import img7 from '@/assets/images/validcode/7.png'
import img8 from '@/assets/images/validcode/8.png'
import img9 from '@/assets/images/validcode/9.png'
import img10 from '@/assets/images/validcode/10.png'
import img11 from '@/assets/images/validcode/11.png'
import img12 from '@/assets/images/validcode/12.png'
import img13 from '@/assets/images/validcode/13.png'
import img14 from '@/assets/images/validcode/14.png'
export default {
  name: 'login',
  data() {
    var validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证码'))
      } else if (value !== this.valiedCodeValue) {
        callback(new Error('验证码错误，请重新输入'))
      } else {
        callback()
      }
    }
    return {
      form: {
        username: '',
        password: '',
        validateCode: '',
      },
      rembered: false,
      picUrl: '',
      rules: {
        username: [
          {
            required: true,
            message: '请输入账号',
            trigger: 'blur',
            whitespace: true,
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
            whitespace: true,
          },
        ],
        validateCode: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
            whitespace: true,
          },
          { validator: validateCode, trigger: 'blur' },
        ],
      },
      btnLoading: false,
      codeId: 1,
      valiedCodeValue: '',
      validCodeArr: [
        {
          code: 1,
          value: '2633',
        },
        {
          code: 2,
          value: '9238',
        },
        {
          code: 3,
          value: '6474',
        },
        {
          code: 4,
          value: '5456',
        },
        {
          code: 5,
          value: '2588',
        },
        {
          code: 6,
          value: '4799',
        },
        {
          code: 7,
          value: '7548',
        },
        {
          code: 8,
          value: '2794',
        },
        {
          code: 9,
          value: '7933',
        },
        {
          code: 10,
          value: '5738',
        },
        {
          code: 11,
          value: '7644',
        },
        {
          code: 12,
          value: '7465',
        },
        {
          code: 13,
          value: '8677',
        },
        {
          code: 14,
          value: '3595',
        },
      ],

      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
      img9,
      img10,
      img11,
      img12,
      img13,
      img14,
    }
  },
  watch: {
    codeId: {
      handler: function (val) {
        this.validCodeArr.forEach((item) => {
          if (item.code === this.codeId) {
            this.valiedCodeValue = item.value
            this.picUrl = this[`img${item.code}`]
          }
        })
      },
      immediate: true,
    },
  },
  mounted() {
    this.initCodeId()
  },
  methods: {
    initCodeId() {
      this.codeId = Math.floor(Math.random() * 14 + 1)
    },
    onSubmit() {
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          this.$message.warning('用户名或密码错误')
          this.changeCode()
        } else {
          if (this.form.username && this.form.password && this.form.validateCode) {
            if (this.form.validateCode !== this.valiedCodeValue) {
              this.changeCode()
              return false
            }
          }
          return false
        }
      })
    },
    changeCode() {
      if (this.codeId === 14) {
        this.codeId = 1
      } else {
        this.codeId++
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$login-height: 480px;
.login-main {
  width: 100%;
  height: 100%;
  position: absolute;
  line-height: 30px;
  color: #00afff;
  font-weight: 600;
  font-size: 17px;
  white-space: nowrap;
  > img {
    width: 30px;
    height: 30px;
    vertical-align: top;
  }
}
.login-main {
  height: 100%;
  width: 100%;
  background: url('~@/assets/images/login-background.png') no-repeat;
  background-size: cover;
  .his-logo {
    position: absolute;
    top: 50px;
    left: 50px;
  }
}
.content {
  width: 890px;
  height: $login-height;
  display: flex;
  margin: auto;
  position: absolute;
  justify-content: flex-end;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.left {
  width: 690px;
  height: $login-height;
  background-image: url('~@/assets/images/login-left.png');
  background-size: cover;
}
.right {
  width: 492px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $login-height;
  position: relative;
  background-color: #fff;
  box-shadow: 2px -2px 20px rgba(0, 0, 0, 0.15);
}
:deep(#formlogin) {
  .el-form-item__error {
    padding-top: 6px;
  }
  .input-content {
    width: 358px;
  }
  .input-box {
    width: 318px;
    overflow: hidden;
  }
  .el-input__inner {
    padding: 0 10px;
    border: none;
  }
  .loginTile {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    color: #404040;
    height: 60px;
    letter-spacing: 6px;
  }
  margin-top: 20px;
  width: 88%;
  .el-input__prefix .login-icon {
    display: inline-block;
    width: 25px !important;
    height: 34px !important;
    line-height: 32px !important;
  }
  .el-input__wrapper {
    padding: 0;
  }
  .logininput {
    height: 35px;
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
  }
  .logininput > input {
    height: 35px !important;
    line-height: 35px !important;
  }

  .login-btn {
    width: 100%;
    border: none;
    background: linear-gradient(to right, #128cea, #69befc);
    box-shadow: 0px 6px 20px 0px rgba(19, 106, 167, 0.35);
    border-radius: 0;
    height: 50px !important;
    font-size: 18px;
    margin-top: 10px;
  }

  .encrypedInput {
    position: relative;
    .encrypedCodeImg {
      position: absolute;
      top: -1px;
      right: 41px;
      cursor: pointer;
      color: #fff;
      text-align: center;
      height: 40px;
      img {
        height: 100%;
        border: 1px solid #eee;
      }
    }
  }
}
.el-input--small .el-input__icon {
  background-color: red;
  width: 14px;
  height: 14px;
}

.el-form-item {
  margin-bottom: 20px;
}

.validateCode-box,
.password-box,
.username-box {
  display: flex;
  width: 430px;
  padding-bottom: 6px;
  border-bottom: 1px solid;
}
.validateCode-box {
  width: 180px;
}
.username-box .el-input,
.password-box .el-input {
  width: 350px;
}
.validateCode-box .el-input {
  width: 315px;
}
.i-username {
  align-self: center;
  width: 20px;
  display: inline-block;
  height: 20px;
  background: url('~@/assets/images/username.png') center no-repeat;
  background-size: 16px;
}
.i-password {
  align-self: center;
  width: 20px;
  display: inline-block;
  height: 20px;
  background: url('~@/assets/images/password.png') center no-repeat;
  background-size: 16px;
}
.i-validateCode {
  align-self: center;
  width: 36px;
  display: inline-block;
  height: 20px;
  background: url('~@/assets/images/validateCode.png') center no-repeat;
  background-size: 16px;
}
.bottmLogo {
  width: 100%;
  position: fixed;
  bottom: 18px;
  font-size: 12px;
  font-weight: 700;
  color: #999;
  text-align: center;
  a {
    color: #999;
    cursor: pointer;
  }
}
.bottom-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  top: -7px;
  span {
    font-size: 12px;
    color: #128cea;
  }
}
</style>
